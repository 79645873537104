<template>
  <b-modal
    id="virtual-phone-setup-modal"
    :hide-header="true"
    :hide-footer="true"
    no-close-on-backdrop
    dialog-class="virtual-phone-setup-dialog"
    body-class="phone-setup-modal-body"
    :size="currentStep === 'Step4' ? 'xl' : 'lg'"
    footer-class="justify-content-center"
  >
    <slot name="body">
      <component :is="currentStep" />
    </slot>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Step1, Step2, Step3, Step4 } from './index.js'

export default {
  name: 'VirtualPhoneSetupModal',

  components: {
    Step1, // Select jurisdiction
    Step2, // Select number to reserve
    Step3, // Name the number
    Step4, // Disable or update mobile access username
  },

  computed: {
    ...mapGetters('virtualPhones', [
      'selectedAvailableNumber',
      'availableNumbers',
      'phoneName',
      'mobileAccessFinished',
      'virtualPhonesToConfigure',
    ]),

    currentStep() {
      switch(true) {
        case this.step3IsDone:
          return 'Step4'
        case this.step2IsDone:
          return 'Step3'
        case this.step1IsDone:
          return 'Step2'

        default:
          return 'Step1'
      }
    },

    step1IsDone() { return this.availableNumbers.length > 0 },
    step2IsDone() { return !!this.selectedAvailableNumber },
    step3IsDone() { return !!this.phoneName },
  },

  async mounted() {
    await this.resetModalFields()
  },

  methods: {
    ...mapActions('virtualPhones', ['resetModalFields', 'loadVirtualPhones']),
  },
}
</script>
